// client/src/ThankYouPage.js
import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import styles from "./assets/ThankYouPage.module.css";
import "./assets/App.module.css";
import logo from "./assets/img/logo.webp";
import { FaHome, FaCheck } from "react-icons/fa";

function ThankYouPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, amount } = location.state || {};
  const [showDetails, setShowDetails] = useState(false); // Yeni durum ekleyin
  const [showMessage, setShowMessage] = useState(false); // Yeni durum ekleyin

  useEffect(() => {
    document.title = "Teşekkürler - SBT | Bağış Sistemi";
    if (!name || !amount) {
      navigate("/"); // Eğer name veya amount yoksa ana sayfaya yönlendir
    } else {
      const timer = setTimeout(() => {
        setShowDetails(true); // 2 saniye sonra detayları göster
        setShowMessage(true); // Mesajı göster
      }, 2000);
      return () => clearTimeout(timer); // Temizleme işlemi
    }
  }, [name, amount, navigate]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  return (
    <div className={styles.thankYouContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo}></img>
        <h1 className={styles.logoTitle}>SBT | Bağış Sistemi</h1>
      </div>
      <FaCheck className={styles.checkmark} />
      <p className={styles.donationInfo}>Bağışınız başarıyla alındı.</p>

      {showMessage && ( // Detayları göster
        <>
          <p
            className={`${styles.message} ${showDetails ? styles.fadeIn : ""}`}
          >
            Sayın <strong>{name}</strong>,
          </p>
          <p
            className={`${styles.message} ${showDetails ? styles.fadeIn : ""}`}
          >
            <strong>{amount} ₺</strong> bağışınız için çok teşekkür ederiz.
          </p>
        </>
      )}

      <Link to="/" className={styles.homeLink}>
        <FaHome className={styles.buttonIcon} />
        Ana Sayfaya Dön
      </Link>
    </div>
  );
}

export default ThankYouPage;
