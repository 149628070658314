// client/src/PaymentPage.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./assets/App.module.css";
import styles from "./assets/PaymentPage.module.css";
import { FaHeart, FaSpinner } from "react-icons/fa";
import logo from "./assets/img/logo.webp";

function PaymentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, amount } = location.state || {};

  useEffect(() => {
    document.title = "Ödeme Sayfası - SBT | Bağış Sistemi"; // Sayfa başlığını güncelle
    if (!name || !amount) {
      navigate("/"); // Eğer name veya amount yoksa ana sayfaya yönlendir
    }
  }, [name, amount, navigate]);

  const [cardData, setCardData] = useState({
    card_name: "",
    card_number: "",
    expiry_date: "",
    cvv: "",
  });
  const [cardError, setCardError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [validations, setValidations] = useState({
    cardNameValid: false,
    cardNumberValid: false,
    expiryDateValid: false,
    cvvValid: false,
  });

  useEffect(() => {
    const allFieldsValid = Object.values(validations).every((value) => value);
    setIsFormValid(allFieldsValid);
  }, [validations]);

  const formatName = (name) => {
    return name
      .replace(/[^a-zA-ZğüşıöçĞÜŞİÖÇ\s]/g, "")
      .replace(/\s+/g, " ")
      .split(" ")
      .map((word) => word.toUpperCase())
      .join(" ");
  };

  const validateName = (name) => {
    const nameParts = name.trim().split(" ");
    return nameParts.length >= 2 && nameParts.every((part) => part.length >= 2);
  };

  const handleCardNameChange = (e) => {
    const value = e.target.value;
    const formattedName = formatName(value);
    setCardData({ ...cardData, card_name: formattedName });
    setCardNameError("");

    const isValid = validateName(formattedName);
    setValidations((prev) => ({
      ...prev,
      cardNameValid: isValid,
    }));
  };

  const handleCardNameBlur = (e) => {
    const value = e.target.value;
    const isValid = validateName(value);

    setValidations((prev) => ({
      ...prev,
      cardNameValid: isValid,
    }));

    if (value.length === 0) {
      setCardNameError("");
    } else if (!isValid) {
      setCardNameError("Lütfen geçerli bir ad ve soyad giriniz");
    } else {
      setCardNameError("");
    }
  };

  const handleCardNumberChange = (value) => {
    const rawValue = value.replace(/\s/g, "");
    const formattedValue = rawValue
      .replace(/\D/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    setCardData({ ...cardData, card_number: formattedValue });
    setCardError("");

    const isValid = rawValue.length === 16 && luhnCheck(rawValue);
    setValidations((prev) => ({
      ...prev,
      cardNumberValid: isValid,
    }));
  };

  const handleCardNumberBlur = () => {
    const rawValue = cardData.card_number.replace(/\s/g, "");
    if (rawValue.length === 0) {
      setCardError("");
    } else if (rawValue.length < 16) {
      setCardError("Kart numarası 16 haneli olmalıdır");
      setValidations((prev) => ({ ...prev, cardNumberValid: false }));
    } else if (!luhnCheck(rawValue)) {
      setCardError("Geçersiz kart numarası");
      setValidations((prev) => ({ ...prev, cardNumberValid: false }));
    } else {
      setCardError("");
      setValidations((prev) => ({ ...prev, cardNumberValid: true }));
    }
  };

  const handleExpiryDateChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, "");
    let formattedValue = cleanedValue;

    if (cleanedValue.length > 2) {
      formattedValue = `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(
        2,
        4
      )}`;
    }

    setCardData({ ...cardData, expiry_date: formattedValue });
    setExpiryError("");
  };

  const handleExpiryDateBlur = () => {
    const value = cardData.expiry_date;
    const cleanedValue = value.replace(/\D/g, "");

    if (cleanedValue.length === 0) {
      setExpiryError("");
      setValidations((prev) => ({ ...prev, expiryDateValid: false }));
    } else if (cleanedValue.length < 4) {
      setExpiryError("Lütfen geçerli bir tarih giriniz");
      setValidations((prev) => ({ ...prev, expiryDateValid: false }));
    } else {
      const month = parseInt(cleanedValue.slice(0, 2), 10);
      const year = parseInt(cleanedValue.slice(2, 4), 10);
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;

      if (month < 1 || month > 12) {
        setExpiryError("Geçersiz ay");
        setValidations((prev) => ({ ...prev, expiryDateValid: false }));
      } else if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        setExpiryError("Kartın süresi dolmuş");
        setValidations((prev) => ({ ...prev, expiryDateValid: false }));
      } else {
        setExpiryError("");
        setValidations((prev) => ({ ...prev, expiryDateValid: true }));
      }
    }
  };

  const handleCvvChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, "").slice(0, 3);
    setCardData({ ...cardData, cvv: cleanedValue });
    setCvvError("");
  };

  const handleCvvBlur = () => {
    const value = cardData.cvv;
    if (value.length === 0) {
      setCvvError("");
      setValidations((prev) => ({ ...prev, cvvValid: false }));
    } else if (value.length < 3) {
      setCvvError("CVV 3 haneli olmalıdır");
      setValidations((prev) => ({ ...prev, cvvValid: false }));
    } else {
      setCvvError("");
      setValidations((prev) => ({ ...prev, cvvValid: true }));
    }
  };

  const luhnCheck = (cardNumber) => {
    let sum = 0;
    let alternate = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let n = parseInt(cardNumber.charAt(i), 10);
      if (alternate) {
        n *= 2;
        if (n > 9) n -= 9;
      }
      sum += n;
      alternate = !alternate;
    }
    return sum % 10 === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      try {
        const rawCardNumber = cardData.card_number.replace(/\s/g, "");
        const rawAmount = amount.replace(/\./g, "");
        setIsLoading(true);

        await axios.post("https://bagis-sistemi-server.vercel.app/api/donate", {
          ...cardData,
          card_number: rawCardNumber,
          name,
          amount: rawAmount,
        });

        setTimeout(() => {
          navigate("/thank-you", { state: { name, amount } });
        }, 2000);
      } catch (error) {
        console.error(
          "Bağış işlemi sırasında bir hata oluştu:",
          error.response ? error.response.data : error.message
        );
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.paymentContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo}></img>
        <h1 className={styles.title}>SBT | Bağış Sistemi</h1>
      </div>
      <h1 className={styles.title}>Ödeme Bilgileri</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputWrapper}>
          <input
            className={`${styles.input} ${
              cardNameError
                ? styles.invalidInput
                : validations.cardNameValid
                ? styles.validInput
                : ""
            }`}
            name="card_name"
            placeholder="Kartın Üzerindeki Ad Soyad"
            value={cardData.card_name}
            onChange={handleCardNameChange}
            onBlur={handleCardNameBlur}
            required
            minLength={5}
            maxLength={50}
          />
          {cardNameError && <p className={styles.error}>{cardNameError}</p>}
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={`${styles.input} ${
              cardError
                ? styles.invalidInput
                : validations.cardNumberValid
                ? styles.validInput
                : ""
            }`}
            name="card_number"
            placeholder="Kart Numarası"
            value={cardData.card_number}
            onChange={(e) => handleCardNumberChange(e.target.value)}
            onBlur={handleCardNumberBlur}
            required
            inputMode="numeric"
            maxLength={19}
          />
          {cardError && <p className={styles.error}>{cardError}</p>}
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputWrapper}>
            <input
              className={`${styles.input} ${styles.inputHalf} ${
                expiryError
                  ? styles.invalidInput
                  : validations.expiryDateValid
                  ? styles.validInput
                  : ""
              }`}
              name="expiry_date"
              placeholder="AA/YY"
              inputMode="numeric"
              value={cardData.expiry_date}
              onChange={handleExpiryDateChange}
              onBlur={handleExpiryDateBlur}
              required
              maxLength={5}
            />
            {expiryError && <p className={styles.error}>{expiryError}</p>}
          </div>
          <div className={styles.inputWrapper}>
            <input
              className={`${styles.input} ${styles.inputHalf} ${
                cvvError
                  ? styles.invalidInput
                  : validations.cvvValid
                  ? styles.validInput
                  : ""
              }`}
              name="cvv"
              placeholder="CVV"
              inputMode="numeric"
              value={cardData.cvv}
              onChange={handleCvvChange}
              onBlur={handleCvvBlur}
              required
              maxLength={3}
            />
            {cvvError && <p className={styles.error}>{cvvError}</p>}
          </div>
        </div>
        <div className={styles.donationInfoContainer}>
          <p className={styles.donationInfo}>Ödenecek Tutar</p>
          <p className={styles.donationAmount}>
            {new Intl.NumberFormat("tr-TR", {
              style: "currency",
              currency: "TRY",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true, // Binlik ayırıcıları kullan
            }).format(parseFloat(amount.replace(/\./g, "")))}
          </p>
        </div>
        <button
          type="submit"
          disabled={!isFormValid}
          className={`${styles.submitButton} ${
            isLoading ? styles.loading : ""
          }`}
        >
          {isLoading ? (
            <FaSpinner className={`${styles.buttonIcon} ${styles.spinner}`} />
          ) : (
            <FaHeart className={styles.buttonIcon} />
          )}
          {isLoading ? "İşleniyor..." : "Bağış Yap"}
        </button>
        <div className={styles.warningArea}>
          <p className={styles.warningText}>
            Gerçek bir bağış sayfası değildir. Test amaçlı projedir. Lütfen
            gerçek bilgilerinizi girmeyin!
          </p>
        </div>
      </form>
    </div>
  );
}

export default PaymentPage;
