// client/src/HomePage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaRegCreditCard, FaSpinner } from "react-icons/fa"; // Bu satırı ekleyin
import styles from "./assets/HomePage.module.css";
import "./assets/App.module.css";
import logo from "./assets/img/logo.webp";

function HomePage() {
  const [formData, setFormData] = useState({
    name: "",
    amount: "",
    isAnonymous: false, // Bu satırı ekleyin
  });
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Bu satırı ekleyin
  const [donations, setDonations] = useState([]);
  const [isFetchingDonations, setIsFetchingDonations] = useState(false); // Yeni durum ekleyin

  useEffect(() => {
    document.title = "SBT | Bağış Sistemi";
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    setIsFetchingDonations(true); // Bağışları yüklerken durumu başlat
    try {
      const response = await axios.get(
        "https://bagis-sistemi-server.vercel.app/api/donations"
      );
      setDonations(response.data);
    } catch (error) {
      console.error("Bağışlar getirilirken bir hata oluştu: ", error);
    } finally {
      setIsFetchingDonations(false); // Yükleme durumunu bitir
    }
  };

  const formatName = (name) => {
    return name
      .replace(/[^a-zA-ZğüşıöçĞÜŞİÖÇ\s]/g, "")
      .replace(/\s+/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const validateName = (name) => {
    const nameParts = name.trim().split(" ");
    return nameParts.length >= 2 && nameParts.every((part) => part.length >= 2);
  };

  const formatAmount = (value) => {
    // Sadece sayıları al ve başındaki sıfırları kaldır
    const numericValue = value.replace(/[^\d]/g, "").replace(/^0+/, "");
    // Binlik ayırıcıları ekle
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const validateAmount = (value) => {
    const numericValue = parseInt(value.replace(/\./g, ""), 10);
    return numericValue >= 1 && numericValue <= 3000000;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "isAnonymous") {
      setFormData({
        ...formData,
        [name]: checked,
        name: checked ? "Anonim Bağışçı" : "",
      });
      setIsNameValid(checked);
    } else if (name === "name") {
      const formattedName = formatName(value);
      setFormData({ ...formData, [name]: formattedName });
      setIsNameValid(validateName(formattedName) || formData.isAnonymous);
    } else if (name === "amount") {
      const formattedValue = formatAmount(value);
      setFormData({ ...formData, [name]: formattedValue });
      setIsAmountValid(validateAmount(formattedValue));
    }
  };

  const handleBlur = (e) => {
    if (e.target.name === "name") {
      if (!validateName(formData.name)) {
        setNameError("Lütfen geçerli bir ad ve soyad giriniz.");
        setIsNameValid(false);
      } else {
        setNameError("");
        setIsNameValid(true);
      }
    }
  };

  const handleAmountSelect = (value) => {
    setFormData({ ...formData, amount: value });
    setSelectedAmount(value);
    setShowCustomAmount(false);
    setIsAmountValid(true);
  };

  const handleCustomAmountClick = () => {
    setFormData({ ...formData, amount: "" });
    setSelectedAmount(null);
    setShowCustomAmount(true);
    setIsAmountValid(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateName(formData.name)) {
      setNameError("Lütfen geçerli bir ad ve soyad giriniz.");
      setIsNameValid(false);
      return;
    }
    setIsLoading(true); // Yükleme durumunu başlat
    setTimeout(() => {
      navigate("/payment", { state: formData });
    }, 2000); // 2 saniye gecikme 2000 yap
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo}></img>
        <h1 className={styles.title}>SBT | Bağış Sistemi</h1>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="isAnonymous"
            name="isAnonymous"
            checked={formData.isAnonymous}
            onChange={handleChange}
          />
          <label htmlFor="isAnonymous">Anonim olarak bağış yap</label>
        </div>
        {!formData.isAnonymous && (
          <>
            <h2 className={styles.donator}>Bağışçı</h2>
            <div className={styles.inputContainer}>
              <input
                className={`${styles.input} ${
                  formData.name !== ""
                    ? isNameValid
                      ? styles.validInput
                      : styles.invalidInput
                    : ""
                }`}
                name="name"
                placeholder="Ad Soyad"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                minLength={5}
                maxLength={50}
                required
              />
              {nameError && <p className={styles.errorText}>{nameError}</p>}
            </div>
          </>
        )}
        <h2 className={styles.secondTitle}>Bağış Miktarı</h2>
        <div className={styles.buttonGroup}>
          {[10, 50, 100, 500, 1000].map((amount) => (
            <button
              key={amount}
              type="button"
              className={`${styles.amountButton} ${
                selectedAmount === amount.toString()
                  ? styles.amountButtonSelected
                  : ""
              }`}
              onClick={() => handleAmountSelect(amount.toString())}
            >
              ₺{amount.toLocaleString("tr-TR")}
            </button>
          ))}
          <button
            type="button"
            className={`${styles.amountButton} ${
              showCustomAmount ? styles.amountButtonSelected : ""
            }`}
            onClick={handleCustomAmountClick}
          >
            Diğer
          </button>
        </div>
        {showCustomAmount && (
          <div className={styles.inputContainer}>
            <input
              className={`${styles.input} ${
                formData.amount !== ""
                  ? isAmountValid
                    ? styles.validInput
                    : styles.invalidInput
                  : ""
              }`}
              name="amount"
              placeholder="Bağış Miktarı (₺)"
              value={formData.amount}
              onChange={handleChange}
              minLength={1}
              maxLength={9}
              inputMode="numeric"
              required
            />
            {!isAmountValid && formData.amount !== "" && (
              <p className={styles.errorText}>
                Lütfen 1 ₺ ile 3.000.000 ₺ arasında bir miktar giriniz.
              </p>
            )}
          </div>
        )}
        <button
          type="submit"
          className={`${styles.submitButton} ${
            isLoading ? styles.loading : ""
          }`}
          disabled={!isNameValid || !isAmountValid || isLoading}
        >
          {isLoading ? (
            <FaSpinner className={`${styles.buttonIcon} ${styles.spinner}`} />
          ) : (
            <FaRegCreditCard className={styles.buttonIcon} />
          )}
          {isLoading ? "Ödemeye Geç" : "Ödemeye Geç"}
        </button>
      </form>

      <div className={styles.donationSummary}>
        {isFetchingDonations ? ( // Spinneri burada gösterin
          <FaSpinner className={`${styles.buttonIcon} ${styles.spinner}`} />
        ) : (
          <p>
            Şimdiye kadar <strong>{donations.length}</strong> adet bağışçımız
            bize toplamda{" "}
            <strong>
              {donations
                .reduce(
                  (total, donation) => total + parseFloat(donation.amount),
                  0
                )
                .toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{" "}
            </strong>{" "}
            bağış yaptı. Hepinize sonsuz teşekkürler!
          </p>
        )}
        <br />
        {donations.length > 0 && (
          <p>
            Son bağışçımız: <strong>{donations[0].name}</strong>
          </p>
        )}
      </div>
    </div>
  );
}

export default HomePage;
